.App {
  text-align: center;
  background-color: #f2f2f2;
  min-height: 100vh;
}

a {
  text-decoration: none !important;
}

.logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f0f0f1;
  font-family: "Open Sans", Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Inicio estilos personalizados */

/* cabecera */

.badgeBio span.MuiBadge-badge{
  width: 20px;
  height: 20px;
  background-color: #d4effa;
  color: #008BFF;
}

.backHeader{
  color: white !important;
  margin-left: 20px;
}

.cabeceraPublica{
  position: absolute;
  right: 0;
}

div#imageId {
  float: left;
}

nav span {
  color: #fff;
  margin-left: 10px;
  line-height: 60px;
}

img.d-inline-block.align-text-top {
  margin-right: 20px;
}

/* Empresa */

.MuiAvatar-img {
  height: auto !important;
}

.MuiAvatar-root {
   margin: 0 auto;
}

.fotoEmpresa {
  justify-content: center;
}

/* Inicio */

.contenido {
  max-width: 720px;
  min-height: 80vh;
  margin: 0 auto;
  overflow: scroll;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  place-items: center;
  background-color: rgb(243 243 243);
  padding-top: 20px;
}

.container-producto {
  margin: 10px;
  display: grid; 
  grid-template-columns: 50% 50%; 
  grid-template-rows: auto; 
  gap: 0 20px; 
  grid-template-areas: 
    ". ."
    ". ."; 
}

.btn-producto {
  width: 100% !important;
}

.producto {
  max-width: 330px;
}

.inicio {
  max-width: 300px;
  margin: auto;
}

@font-face {
  font-family: Sansation;
  src: local("Sansation_Regular"),
  url('./fonts/Sansation/Sansation_Regular.ttf') format("truetype");
}

.container .tituloInicio {
  text-align: center;
  font-family: Sansation;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.9);
}

.container span {
  font-family: 'Sansation';
}

.imageMenu {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 40px;
}

.spanTitulo{
  font-size: 30px !important;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  color:#0099cc;
}

.tituloProducto {
  color: #000 !important;
  font-size: 20px !important;
}

.descripcionProducto {
  color: #333;
  font-size: 15px !important;
}

.spanCategoriaTitulo{
  font-size: 20px !important;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  color:#000;
}

.spanCategoria{
  font-size: 20px !important;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  color:#004258;
}

/* login */

.form-container {
  padding: 24px;
  margin: 24px;
  border-radius: 50px;
}

.container-title {
  padding-top: 20px;
}

.pageTitle {
  padding-top: 20px;
}


.login input[type="text"],
.login input[type="password"],
.login input[type="submit"]{
  border: 1px solid gray;
  border-radius: 25px;
  padding: 10px 50px 10px 50px;
  width: 100%;
  padding: 1rem 0.75rem !important;
}

.imagen-login {
  margin-bottom: 40px;;
}

.form-control {
  margin: 0 auto;
}

label.labelForm {
  margin: 10px auto;
  font-weight: bold;
}

.form-group {
 text-align: left;
}

.recoveryPass a {
  font-weight: 100;
  font-size: 15px;
  margin-top: 20px;
  text-decoration: none !important;
  color: #000;
}

.derecha {
  justify-content: end !important;
}

.btn {
  border: none !important;
}

.btn-primary {
  border: none;
}

.btn-azul{
  background-color: blue;
  background-image:
    linear-gradient(
     #1DBBFF, #204B83
    );
  border-radius: 50px !important;
  font-weight: 100;
  color: #fff;
}

.btn-azul a,
.btn-azul a:hover {
  color: #fff !important;
}

.btn-rojo {
  background-color: #f81e0a !important;
  border-radius: 50px !important;
  font-weight: 100;
  color: #fff;
}

.btn-rojo a,
.btn-rojo a:hover {
  color: #fff;
}

.bg-azulbio {
  min-height: 10vh;
  background-color: blue;
  background-image:
    linear-gradient(
     #1DBBFF, #204B83
    );
}

.boton-addUser {
  background-color: #0099cc !important;
  border-radius: 50px !important;
  font-weight: 100;
  border: none !important;
}

.boton-addUser a {
  text-decoration: none;
  color: #fff;
  border: none;
}

.btn-danger {
  --bs-btn-padding-y: 0.5rem !important;
    --bs-btn-padding-x: 1rem !important;
    --bs-btn-font-size: 1.25rem !important;
    --bs-btn-border-radius: 0.5rem !important;
  width: 80%;
  border-radius: 50px !important;
  font-weight: 100;
  margin-top: 15px;
}

.boton-azulbio {
  width: 80%;
  border-radius: 50px !important;
  font-weight: 100;
}

.boton-login {
  width: 100% !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  letter-spacing: 0em !important;
  text-align: center !important;

}
.boton-perfil {
  width: 40% !important;
  font-size: 0.8em !important;
}

.form-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Footer */

.footer {
  min-height: 10vh;
  padding-top: 15px;
  padding-bottom: 15px;
}

#nav-item-4{
  margin-top: 2px;
}

.footerContacto {
  float: right;
  margin-right: 20px;
  text-decoration: none;
  color: #000;
}

#form-contacto textarea {
  padding: 30px;
}

.footer a {
  text-decoration: none;
  color: #212529;
}

.footer a:hover {
  text-decoration: none;
  color: #212529;
}

.btn:hover {
  border: none;
}

/** Usuarios  ***/

.listaVeterinarios{
  list-style: none;
  padding-left: 0;
}

.itemVeterinarios {
  margin-bottom: 30px;
}

nav.navbar.navbar-light.bg-light.justify-content-around {
  margin-bottom: 20px;
}

.MuiButtonBase-root {
  font-family: Sansation !important;
}

.MuiButtonBase-root.Mui-selected {
  color: #0099cc !important;
}

.MuiTabs-indicator {
  background-color: #0099cc !important;
}
.MuiSwitch-root{
  background-color: transparent !important;
  margin: 0px;
  border: none !important;
}

.MuiTypography-root {
  box-shadow: none !important;
  text-decoration: none !important;
  color: #000;
  font-family: Sansation !important;
}

.MuiTypography-h5 {
  font-size: 1.1rem !important;
} 

.MuiListItemText-primary {
  font-weight: bold !important;
}

.MuiList-root {
  margin: 10px !important;
  max-width: 720px !important;  
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #0099cc !important;
}

.card-body {
  padding: 0 !important;
}

.card-footer {
  display: flex;
  justify-content: space-around;
  background-color: transparent !important;
}

span.MuiSwitch-root.MuiSwitch-sizeMedium.form-check-input.css-julti5-MuiSwitch-root {
  border-radius: 50px;
  margin-top: -3px;
  margin-left: 20px;
}

.buscador {
  width: 90% !important;
  margin-left: 10px;
}

.buscador-completo {
  width: 90% !important;
}

.buscador-informes{
  margin-left: 10px;
}

.labelFormularios {
  margin: 0 !important;
  padding: 0 !important;
  display: contents !important;
}

.boton-formularios {
  width: 86% !important;
}

.switchRespuesta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

fieldset.MuiFormControl-root{
  width: 100%;
}

.actionsForms {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contenedorPregunta {
  margin-top: 30px;
  border-bottom: 1px solid #383838;
}

.enunciadoPregunta {
  text-align: left;
}

.spanPuntuacion {
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

/** Mi perfil */
.card {
  width: 450px;
  height: 250px;
  background-color: #fff;
  background: linear-gradient(#f8f8f8, #fff);
  box-shadow: 0 8px 16px -8px rgba(0,0,0,0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  margin: 1.5rem;
}

.card h1 {
  text-align: center;
}

.card .additional {
  position: absolute;
  width: 150px;
  height: 100%;
  background: linear-gradient(#dE685E, #EE786E);
  transition: width 0.4s;
  overflow: hidden;
  z-index: 2;
}

.card.green .additional {
  background: linear-gradient(#92bCa6, #A2CCB6);
}
.card .additional .user-card {
  width: 150px;
  height: 100%;
  position: relative;
  float: left;
}

.card .additional .user-card::after {
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  right: -2px;
  height: 80%;
  border-left: 2px solid rgba(0,0,0,0.025);
}

.card .additional .user-card .level,
.card .additional .user-card .points {
  top: 15%;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: bold;
  background: rgba(0,0,0,0.15);
  padding: 0.125rem 0.75rem;
  border-radius: 100px;
  white-space: nowrap;
}

.card .additional .user-card .points {
  top: 85%;
}

.card .additional .user-card svg {
  top: 50%;
}

.card .additional .more-info {
  width: 300px;
  float: left;
  position: absolute;
  left: 150px;
  height: 100%;
}

.card .additional .more-info h1 {
  color: #fff;
  margin-bottom: 0;
}

.card.green .additional .more-info h1 {
  color: #224C36;
}

.card .additional .coords {
  margin: 0 1rem;
  color: #fff;
  font-size: 1rem;
}

.card.green .additional .coords {
  color: #325C46;
}

.card .additional .coords span + span {
  float: right;
}

.card .additional .stats {
  font-size: 2rem;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  top: auto;
  color: #fff;
}

.card.green .additional .stats {
  color: #325C46;
}

.card .additional .stats > div {
  flex: 1;
  text-align: center;
}

.card .additional .stats i {
  display: block;
}

.card .additional .stats div.title {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}

.card .additional .stats div.value {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
}

.card .additional .stats div.value.infinity {
  font-size: 2.5rem;
}

.card .general {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  box-sizing: border-box;
  padding: 1rem;
  padding-top: 0;
}

.card .general .more {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.9em;
}

/* dashboard*/

.titulo h5{
  font-size: 18px;
}

.descripcion {
  font-size: 14px;
}

.tarjeta-menu {
  display: flex;
  padding: 20px;
  align-items: center;
}

.descripcion p {
  margin-bottom: 0px;
}

.texto {
  margin-left: 20px;
  text-align: left;
}

.centrado {
  text-align: center !important;
}

.iconoInicio {
  color: #0099cc !important;
}

.menu a {
  text-decoration: none;
  color: #212529;
}

.menu a:hover{
  text-decoration: none;
  color: #212529;
}


/* mi perfil */

.formPerfil {
  background-color: #eee;
  margin: 20px;
  border-radius: 25px;
  padding: 10px;
}

.userEdit, .notasEdit, .formEmpresa, .granjaEdit, .navesEdit, .tareaEdit{
  background-color: #eee;
  margin: 20px;
  border-radius: 25px;
  padding: 10px;
}

.boton-notas{
  margin: auto;
}

.formPerfil input {
  border-radius: 10px;
}
.fotoPerfil{
  justify-content: center;
}

.MuiPaper-root {
  margin: 20px !important;
}

.MuiCardActions-root {
  justify-content: space-around !important;
}

.formTitle span {
  font-size: 30px;
}

.form-pass{
  font-size: 20px !important;
}

.addUser:hover {
  color: #fff;
}

/* Contacto */

textarea {
  display: block;
}

input[type='submit'] {
  display: block;
}

.textContact {
  font-size: 25px !important;
}

/* producto */

.contenedorListadoCategoriasProducto{
  margin: 30px;
}

.container-producto .spanProducto {
  text-decoration: none;
  cursor: pointer;
  color: #0099cc;
  font-size: 20px;
}

.container-fichaProducto {
  text-align: left !important;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  margin: 20px;
  place-items: center;
  background-color: rgb(243 243 243);
}

.container-notificacion {
  text-align: left !important;
  flex-direction: column;
  align-items: center;
  place-items: center;
  background-color: rgb(243 243 243);
}

button.notificationIconButton {
  color: #000 !important;
}

.notification-action {
  width: 100%;
}

.notification-action .MuiListItemIcon-root {
    width: auto !important; 
    display: flex; 
    justify-content: space-between;
}

.notification-enlace a {
  color: #0099cc;
}

.notificationIconButton {
  border: none;
  background: transparent;
}

.container-notificacion .MuiList-root {
  max-width: 90% !important;
  margin-inline: auto !important;
}

.notificacion {
  opacity: 0.5;
}

.notificacion,
.notificacionPendiente {
  opacity: 1 !important;
  width: auto !important;
}


.botonPdf {
  font-size: 15px !important;
}

/** Tareas */

.fc {
  font-size: 0.7em !important;
}
.fc-event-warning {
  background-color: #ed9c28 !important;
  
}

.fc-event-success {
  background-color: #47a447 !important;
}

.fc-event-info {
  background-color: #5bc0de !important;
}

.fc-h-event {
  border: none !important;
}

.containerCalendario {
  padding-inline: 20px;
}

/* Evolucion */

.contenedorEvolucion {
  padding-inline: 20px;
  padding-top: 20px;
}

.bottom-nav-item.active {
  background-color: #0099cc !important;
}

.spinnerContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 999;
    overflow: hidden;
}

.containerLoading {
  display: none;
}

/* granjas */

.form-filter {
  display: flex;
  text-align: center;
  margin: 10px;
}

.selectFilter {
  width: 50% !important;
}

.labelColum {
  width: 50%;
  font-size: 14px;
}