.imagenList{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0px;
    justify-content: center;
    align-items: center;
}
.imagenItem{
    position: relative;
}
.imagenItem img.imageItem{
    width: 200px;
    height:auto;
}
.listadoProducto {
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.iconAmpliar{
    position: absolute;
    top:10px;
    left: 10px;
    width: 20px;
}
.iconBorrar{
    position: absolute;
    top:10px;
    right: 10px;
    width: 20px;
}