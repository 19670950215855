body {
  margin: 0;
  font-family: Sansation, -apple-system, BlinkMacSystemFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Sansation;
  font-weight: 600 !important;
}

code {
  font-family:Sansation, 'Courier New',
    monospace;
}
